#loading-modal-container {
  position: absolute;
  opacity: 1;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #7d83ff;
  backdrop-filter: blur(25px);
  transition: opacity 1s;

  overflow: hidden;
}

#loading-modal-container.hide {
  opacity: 0;
}

#subject-modal-background {
  position: fixed;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 4;

  background: #37385699;
  backdrop-filter: blur(1px);
}

#subject-modal-background > div {
  max-width: 541px;
  width: 80vw;
  height: 70vh;

  display: flex;
  flex-direction: column;

  padding: 30px;

  background: var(--bg-primary);
  border-radius: 3%;
}

#subject-modal {
  position: relative;
  overflow-y: auto;
}

#subject-modal > div {
  padding-left: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#subject-modal > div p {
  color: #aaabcb;
  font-weight: normal;
}

#close-modal-btn:hover {
  cursor: pointer;
}

#modal-left-btn {
  height: 10px;
}

#modal-left-btn > p {
  font-size: var(--small-text) !important;
}

#subject-modal > p:first-of-type {
  padding-left: 0.5vw;

  padding-top: 15px;
  color: var(--text-primary);
  font-size: var(--large-text);
  font-weight: 700;
}

#subject-modal > p:nth-of-type(2) {
  padding-left: 0.5vw;

  color: var(--text-secondary);
  font-size: var(--small-text);
  padding-top: 3px;
  margin-bottom: 15px;
  font-weight: 400;
}

#subject-modal hr {
  background-color: var(--subject-todo);
  margin: 4vh 0;
  border-width: 0;
  height: 2px;
}

#subject-modal .btn {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2vh;
}

#subject-modal .btn:hover {
  cursor: pointer;
}

#subject-modal .btn p {
  margin-left: 10px;
  font-size: var(--small-text);
  color: var(--text-primary);
  font-weight: 500;
}

#subject-info {
  display: flex;
  justify-content: flex-start !important;
  margin-top: 10px;
}

#subject-info table tr td:first-of-type {
  padding-right: 2.5vw;
  color: var(--text-gray);
  font-weight: 300;
}

#subject-info table tr td:nth-of-type(2) {
  color: var(--text-primary);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

#subject-program {
  justify-content: flex-start !important;
  align-items: flex-start !important;
  display: flex;
  flex-direction: column;
}

#subject-program > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#subject-program > div > p {
  color: var(--text-primary);
  font-size: var(--regular-text);
  font-weight: 600;
  margin-left: 8px;
}

#subject-program > p {
  color: var(--text-primary) !important;
  font-size: var(--small-text);
}

#subject-program hr {
  background-color: var(--subject-todo);
  margin: 1vh 0;
  border-width: 0;
  height: 2px;
}

#subject-modal-background #subject-modal {
  width: 80%;
}

#subject-modal-background #subject-comments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-wrap: break-word;
  overflow-y: scroll;
  max-height: 60%;
}

.subject-comment {
  background-color: var(--subject-todo);
  border-radius: 5px 20px 20px 20px;
  padding: 1.5vw;
  margin-bottom: 2vh;
  margin-right: 1vw;
  overflow-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
}

.subject-comment p:first-of-type {
  color: var(--text-secondary) !important;
  font-size: var(--x-small-text);
}

.subject-comment p:nth-of-type(2) {
  color: var(--text-primary) !important;
  font-size: var(--small-text);
}

#comment-form {
  position: absolute;
  align-self: stretch;
  max-width: unset;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
}

#comment-form input {
  border-radius: 30px 0 30px 30px;
  flex: 0.98;
}

#comment-form button {
  border-radius: 50%;
}

@media (max-width: 1200px) {
  #subject-modal-background > div {
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    padding: 30px;

    background: var(--bg-primary);
    border-radius: 3%;
  }
}

@media (max-width: 900px) {
  #subject-modal-background > div {
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    padding: 30px;

    background: var(--bg-primary);
    border-radius: 3%;
  }
}
