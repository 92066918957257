#container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  min-height: 100vh;
}

#forgot-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header h2 {
  text-align: left;
}

#back-btn {
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

#back-btn:hover {
  cursor: pointer;
}

#back-btn p {
  color: var(--bg-secondary);
  font-weight: bold;
  margin: 0;
}

#forgot-container input {
  margin-top: 25px;
}

@media (min-width: 1200px) {
  #forgot-container {
    flex: 0.5;
    width: 100%;
  }
}
