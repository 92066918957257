.flowchart-selection-page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-secondary);
}

.flowchart-selection-page-container {
  display: flex;
}

.flowchart-selection-page-container h1 {
  text-align: center;
  color: white;
}

.flowchart-selection-page-container h2 {
  margin-bottom: 5px;
  font-weight: 400;
  color: white;
}

.flowchart-selection-page-container #flowcharts-container ::-webkit-scrollbar {
  width: 0.5vw;
  height: 0.5vw;
}

.flowchart-selection-page-container ::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 100vw;
  margin-block: 0.5em;
}

.flowchart-selection-page-container
  #flowcharts-container
  ::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 100vw;
}

.flowchart-selection-page-container #flowcharts-container {
  margin-top: 10px;
  overflow-y: auto;

  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1100px;
  width: 90%;
}
.flowchart-selection-page-container #flowcharts-container::after {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  content: '';
  background: linear-gradient(
    to top,
    var(--bg-secondary) 20%,
    rgba(255, 255, 255, 0) 80%
  );
  pointer-events: none; /* so the text is still selectable */
}

.flowchart-selection-page-container #flowcharts-container button {
  background: none;
  width: max-content;
  height: min-content;
  color: white;
  font-weight: 300 !important;
  font-family: 'Poppins', sans-serif;
  font-size: var(--regular-text);
  border: 2px dashed transparent;
  padding: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flowchart-selection-page-container #flowcharts-container button:hover {
  animation: blinking 0.4s;
  font-weight: bold !important;
  border: 2px dashed white;
}

.flowchart-selection-page-container #flowcharts-container button:active {
  opacity: 1 !important;
}

@keyframes blinking {
  0% {
    border-color: #ffffff;
  }
  25% {
    border-color: #ffffff70;
  }
  50% {
    border-color: #ffffff;
  }
  75% {
    border-color: #ffffff70;
  }
  100% {
    border-color: #ffffff;
  }
}
