#container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  min-height: 100vh;
}

#login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#login-container h2 {
  align-self: stretch;
  text-align: left;
}

#login-container p:first-of-type {
  color: #868ea4;
  text-align: justify;
}

#login-container span {
  color: #868ea4;
  font-weight: 300;
}

#login-container span:first-of-type {
  margin-bottom: 40px;
}

#forgot-password-btn {
  text-decoration: none;
  color: var(--bg-secondary);
  font-weight: 500;
}

@media (min-width: 1200px) {
  #login-container {
    flex: 0.5;

    width: 100%;
  }
}
