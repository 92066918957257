#page-container {
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
}

#page-container-loading {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

#privacy-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #7d83ff55;
  backdrop-filter: blur(2px);
}

#privacy-modal {
  max-width: 400px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 20px;

  background: var(--bg-primary);
  border-radius: 3%;
}

#privacy-modal > div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

#loading-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}

#privacy-modal > div > p {
  color: var(--text-gray);
  font-size: var(--small-text);
}

#comments-privacy {
  display: flex;
  align-items: center;
}

#comments-privacy > p {
  font-size: var(--small-text);
  margin-left: 10px;
}

#page-container.student-flowchart-page-container #page-header h1 {
  text-align: center;
}

#page-container.student-flowchart-page-container #page-header button {
  padding: 20px;
}

#page-header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--bg-secondary);
}

#page-header h1 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: normal;
  color: white;
}

#page-header button {
  width: max-content;
  padding: 10px;
  font-weight: normal;
  justify-content: center !important;
  align-items: center !important;
}

#logout-icon {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.page-title {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-size: var(--regular-text);
  font-weight: bold !important;
}

.page-title:hover {
  cursor: pointer;
}

#semesters-container {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex: 1;
  max-width: 100vw;
  min-height: 80vh;
  overflow: auto;
  padding-top: 20px;
}

.semester-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
}

.semester-title {
  color: var(--text-primary);
  font-weight: 300;
}

.subject-container {
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  justify-content: center;
  margin: 10px 20px;
  padding: 10px;
  width: 80px;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  transition: all 0.05s ease-in;
  word-break: break-word;
}

.subject-container:hover {
  cursor: pointer;
}

.subject-container * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.subject-code {
  font-size: var(--xx-small-text);
  font-weight: 400;
  margin: 0;
  text-align: center;
}

.subject-name {
  font-size: var(--x-small-text);
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
}

.hidden {
  visibility: hidden;
}

#archived-subjects-container {
  width: 90%;
  max-width: 1100px;
  align-self: center;
  display: flex;
  flex-direction: column;
}

#archived-subjects-container #subjects-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.prerequisite-line {
  opacity: 0.8;
}

@media (min-width: 1200px) {
  #semesters-container {
    justify-content: flex-start;
  }

  .flowchart-page-container #options-container {
    margin-bottom: 0;
  }

  #archived-subjects-container #subjects-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 800px) {
  .subject-container {
    width: 90px;
  }

  #archived-subjects-container #subjects-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1500px) {
  .subject-container {
    width: 140px;
  }
}
