#container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  min-height: 100vh;
}

#register-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#register-container h2 {
  font-size: 40pt;

  align-self: stretch;
  text-align: left;
}

#register-container p:first-of-type {
  color: #868ea4;
  text-align: justify;
}

#register-container span {
  color: #868ea4;
  font-weight: 300;
}

#register-container span:first-of-type {
  margin-bottom: 40px;
}

#register-container form button {
  border: none;
  background: var(--bg-secondary);

  color: var(--text-tertiary);
  font-weight: normal;

  padding: 25px;
  cursor: pointer;
}

#register-container form input:focus {
  outline: 2px solid #7d83ff;
}

.form-field-container {
  display: flex;
  flex-direction: column;
}

.form-field-container div {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.form-field-container div p {
  color: var(--text-red) !important;
  margin-left: 10px;
}

#register-container form .student-subject-container {
  display: flex;
  width: max-content;
  align-items: center;
  background: #7d83ff;
  padding: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

#register-container form .student-subject-container span {
  color: #fff;
  font-weight: bold;
  margin-bottom: 0;
}

#register-container form .student-subject-container button {
  padding: 5px;
}

#register-container #options-container {
  display: flex;
  justify-content: space-around;
}

#register-container #options-container button {
  width: 100%;
  background: #fff;
  color: #7d83ff;
  transition: all 0.5s;
}

#register-container #options-container button.selected {
  background: #7d83ff;
  color: #fff;
}

#create-accout-link {
  color: var(--text-red);
  background-color: var(--text-red);
}

@media (min-width: 1200px) {
  #register-container {
    flex: 0.5;

    width: 100%;
  }
}
