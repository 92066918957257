@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Londrina+Solid:wght@100;300;400;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
button,
input {
  margin: 0;
  padding: 0;
  outline: none;
}

/* Custom Scrollbar */

::-webkit-scrollbar {
  width: 0.5vw;
  height: 0.5vw;
}

::-webkit-scrollbar-track {
  background: var(--bg-primary);
  background-color: var(--bg-primary);
  border-radius: 100vw;
  margin-block: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: #212c4635;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #212c4650;
  border-radius: 100vw;
}

:root {
  --bg-primary: #fffbfe;
  --bg-secondary: #7d83ff;
  --subject-done: #7d83ff;
  --subject-doing: #ffbaba;
  --subject-todo: #f4f5ff;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

button {
  border: none;
  background: var(--bg-secondary);

  color: var(--text-tertiary);

  padding: 25px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

button:active {
  opacity: 0.8;
}

form {
  max-width: 500px;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

form > * {
  margin-bottom: 20px;
}

form input {
  background: var(--subject-todo);
  padding: 20px;
  border: none;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

form input::placeholder {
  color: var(--text-light-purple);
}

form input:focus {
  outline: 2px solid var(--bg-secondary);
}

form textarea {
  background: var(--subject-todo);
  padding: 20px;
  height: 200px;
  border: none;
  outline: none;
  resize: none;
}

form select {
  background: var(--subject-todo);
  padding: 10px;
  border-color: var(--subject-todo);
  border-right: 2px var(--subject-todo) solid;
  outline: none;
  font-family: 'Poppins', sans-serif;
}

/* Font sizes */
:root {
  --xx-small-text: 8pt;
  --x-small-text: 10pt;
  --small-text: 12.5pt;
  --regular-text: 15pt;
  --large-text: 25pt;
  --x-large-text: 40pt;
  --xx-large-text: 40pt;

  --text-primary: #212c46;
  --text-secondary: #7d83ff;
  --text-tertiary: #fffbfe;
  --text-light-purple: #b4b8ff;
  --text-gray: #aaabcb;
  --text-red: #ff7474;
}

h1 {
  font-size: var(--large-text);
  color: var(--text-primary);
}

h2 {
  /* font-size: 32pt; */
}

h3 {
}

p {
  font-size: var(--regular-text);
  color: var(--text-primary);
}

form button,
form span,
form select,
form textarea,
form label {
  font-size: var(--small-text);
}

button,
form input::placeholder,
form label,
form input {
  font-size: var(--regular-text);
}

button {
  font-weight: bold !important;
}

form label {
  font-weight: bold;
}
