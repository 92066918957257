#error-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

#error-msg {
  margin-top: 5px;
  color: var(--text-red) !important;
  font-size: var(--small-text) !important;
}
